@import 'styles-variables.scss';

/*
These primeng style files covers all components and is large because of that.
We may want to manually edit them down. But we also need to see how they are
going to play our own theming and styling.
https://www.primefaces.org/primeng-v15-lts/installation#styles
 */
@import 'primeng/resources/themes/md-light-indigo/theme.css';
@import 'primeng/resources/primeng.css';

html,
body {
  height: 100%;
  background: $main-background-color;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}
h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
}
header {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

/* Used to add styling to short-message and site-message. If we want to be
more dynamic with styling, look up CSP and nonce tokens. */
.center-content {
  text-align: center;

  ul {
    list-style-position: inside;
  }
}
